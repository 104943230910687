import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import * as styles from './styles/404.module.css'
import Button from '../components/button'
import HexAnimation from '../components/hexAnimation'
import { getTextCopy } from '../helpers'

const NotFoundPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    seoImage,
    pageSlug,
    content,
  } = data.notFoundPage
  const { homePageSlug } = data.homePage
  const extraMeta = [
    {
      name: `robots`,
      content: `noindex`,
    },
  ]

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
        extraMeta={extraMeta}
      />
      <Layout>
        <Section>
          <HexAnimation />
          <div className={styles.container}>
            <h1 className={styles.heading}>
              {getTextCopy(content, 'page.notFound.header')}
            </h1>
            <p className={styles.tagline}>
              {getTextCopy(content, 'page.notFound.subheader')}
            </p>
            <Link to={homePageSlug}>
              <Button action="secondary">
                {getTextCopy(content, 'page.notFound.button')}
              </Button>
            </Link>
          </div>
        </Section>
      </Layout>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  {
    notFoundPage: contentfulPage(name: { eq: "page.notFound" }) {
      ...pageFields
    }
    homePage: contentfulPage(name: { eq: "page.home" }) {
      homePageSlug: slug
    }
  }
`
